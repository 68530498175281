@import "resources/styles/scss/mixins.scss";
@import "resources/styles/scss/variables.scss";

.kebab {
    &-wrap {
        right: 0;
        height: 100%;
        position: fixed;
        z-index: 100;
        // background: rgba(black, .3);
        width: column(12);
    }

    &-bg-body {
        position: absolute;
        right: 0;
        top: 0;
        width: column(4);
        background: rgba(black, .9);
        height: 100%;
        padding: 20px;


        @include max($maxMD) {
            width: column(8);
        }

        @include max($maxSM) {
            width: column(12);
        }

        .link-wrap {
            margin-top: 7em;
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 3em;

            @include max($maxMD) {
            margin-top: 2em;
            margin-bottom: 3em;
            }
            

            a {
                text-align: center;
                margin-bottom: .5em;
                line-height: 150%;
                font-size: 2.5em;
                font-family: $font-roboto;
                font-weight: 300;
                color: $white2;
                text-decoration: none;
                text-transform: uppercase;

                @include max($maxMD) {
                    font-size: 2.2em;
                    font-weight: 320;
                    line-height: 130%;
                }

                &:hover {
                    color: $red2;
                }
            }
        }
    }

    &-socialmedia-wrap {
        display: flex;
        justify-content:center;

        .SocMed__Icons {
            width: 100%;
            display: flex;
            justify-content: center;

            .icon {
                width: 50px;
                margin: 5px;
            }
        }

        svg {
            width: 100%;
        }
    }

    &-copy {
        margin-top: 1.5em;
        margin-bottom: 4em;
        h4 {
            font-weight: 100;
            font-size: 1.1em;
            color: $white2;
            text-align: center;
        }
    }

    &-close {
        width:100%;
        display: flex;
        justify-content: center;
        svg {
            &:hover {
                cursor: pointer;
            }
            path {
                fill: white;
            }
        }
    }

}