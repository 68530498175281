@import "../../../resources/styles/scss/variables.scss";
@import "../../../resources/styles/scss/mixins.scss";

.footer {
  background: $dark;
  display: flex !important;
  align-items: center;
  background: $red3;

  .component {
    padding-left: 2.5em;
    padding-right: 2.5em;
    padding-top: 8.4375em;
    padding-bottom: 2em;

    .inner-wrap {
      width: 100%;
      margin-bottom: 2em;
      display: flex;

      @include max($maxMD) {
        flex-direction: column;
      }

      .col {
        display: flex;
        flex-direction: column;
        position: relative;

        .deck {
          display: flex;
          flex-grow: 1;
          width: 100%;
          flex-direction: column;

          a {
            font-size: 1.9em;
            color: $white1;
          }

          p, h2, h3, h4 {
            color: $white1;
          }

          p,a {
            font-weight: 300;
          }
        }

        .icons {
          display: flex;

          @include max($maxMD) {
            justify-content: center;
          }

          img, a {
            margin-right: 1em;
            width: 3.5em;

            @include max($maxMD) {
              width: 3.5em;
              margin: 0 5px;
            }
          }
        }


        &__1 {
          .deck__1 {
            .SocMed {
              img {
                max-width: 15rem;
              }
            }
          }
        }

      

        &__2 {
          .deck__1 {
            @include max($maxMD) {
              margin-top: 3.8em;
              margin-bottom: 3.5em;
            }
            .SocMed {
              display: flex;
              justify-content: center;
              align-items: center;
              // border: 1px solid white;

              

              .SocMed__Icons {
                margin-bottom: 1em;
                display: flex;
                justify-content: center;
                // bordeR: 1px solid orange;
                width: 250px;

                .icon  {
                  width: 50px;
                  margin-right: 4px;

                  a {
                    margin: 0;
                  }

                  svg {
                    width: 100%;
                  }
                }
              }
            }
            a {
              font-family: $font-roboto;
              font-size: 1.7em;
              font-weight: 400;
              line-height: 1.7em;

              &.mobilenumber {
                display: flex;
                align-items: center;
                svg {
                  width: 30px;
                  margin-right: 10px;
                }
              }
            }

            
            .contacts {
              text-align: center;
              @include max($maxMD) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                line-height: 1.5em;
              }
            }
          }
        }

        &__3 {
          position: relative;
          transform: translateX(4em);
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 33.33%;

          @include max($maxMD) {
            max-width: 100%;
            transform: translateX(0);
            align-items: center;
            justify-content: center;
          }

          .deck__1 {
            width: 250px;
          }
          .meta-zombie {
            text-align: center;
            line-height: 150%;
            @include max($maxMD) {
              text-align: center;

            }
            h6 {
              color: #fff;
            }
          }

          .zhd {
            text-align: center;;
            @include max($maxMD) {
              margin-bottom: 1.1rem;
              text-align: center;
            }
            margin-top: 1rem;
            margin-bottom: 1.2rem;
            a {
              font-family: $font-roboto;
              font-weight: 400;
              font-size: 1.45rem;
            }
          }

          .discord-btn {
            display: flex;
            justify-content: center;
          }

        }
      }

      .footer-copy {
        text-align:  center;
        width: 100%;

        h4 {
          font-weight: 100;
          font-size: 1.3em;
          margin-top: 6em;
          color: #fff !important;

          @include max($maxMD) {
            margin-top: 4.5em;
            margin-bottom: 5em;
            font-weight: 100;
            font-size: 1em;
          }
        }
      }
    }
  }
}
