@import "resources/styles/scss/mixins.scss";
@import "resources/styles/scss/variables.scss";

.vouchers {
    position: relative;
    z-index: 1;
    background: $red3;

    .component {
        padding: 0 2.5em;

        @include max($maxSM) {
            padding: 0;
        }
    }

    &__inner-wrap {
        margin-top: -1em;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .img-wrap {
            padding: 1em;
            width:100%;

            &:hover {
                cursor: pointer;
            }

            @include max($maxSM) {
                padding-left: 0;
                padding-right: 0;
            }

            .rsp-img {
                width: 100%;
                display: block;
            }
        }

        &.static-images {
            .img-wrap {
                @include min($minMD) {
                    max-width: 50%;
                }
            }
        }
    }
}