
@import 'resources/styles/scss/mixins.scss';
@import 'resources/styles/scss/variables.scss';

.title-generic {
    .segment {
        color: $default;
    }

    .segment2 {
        color: $red2;
    }
}