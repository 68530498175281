@import "../../../resources/styles/scss/mixins.scss";
@import "../../../resources/styles/scss/variables";


#ContactUs {
    margin-top: -215px;
   
    @include max($maxSM) {
        margin-top: -150px;
    }

    .inner-wrap {
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        padding: 0;
        background-color: $white2;
        min-height: 500px;

        @include max($maxSM) {
            min-height: 300px;
        }

        #contact-us-form-wrap {
            .mbd-title {
                margin-bottom: 60px;

                @include max($maxSM) {
                    margin-bottom: 30px;
                }
              
                h1 {
                    @include max($maxSM) {
                        text-align: center;
                    }
                }
            }
        }

        #contact-us-form {
            .mbd-title {
                margin-bottom: 60px;

                @include max($maxSM) {
                    $margin-bottom: 30px;
                }

            }
        }

        .img-wrap {
            @include min($minMD) {
                width: 360px;
                min-height: 600px;
            }

            background-image: url('../../../resources/images/png/firststep.jpg');
            background-size: cover;
            background-position: bottom center;
        }
    }
    
    .form-wrap {
        flex-grow: 1;
        padding: calc($base-root-size * 6) calc($base-root-size * 9);

        @include max($maxSM) {
            padding: calc($base-root-size * 4);
        }
    }

    #mbd-submit-contact-form {
        @include max($maxSM) {
            width: 100%;
        }
    }
}