@import "../../../resources/styles/scss/variables.scss";
@import "../../../resources//styles/scss/mixins.scss";

.gray-banner {
    height: 400px;
    width: 100%;
    background-repeat: repeat;

    @include max($maxSM) {
        height: 360px;
    }
}