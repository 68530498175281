@import "../../../resources/styles/scss/mixins.scss";
@import "../../../resources/styles/scss/variables";

@media (min-width: 0) and (max-width: 991px) {
  .left-c-arrow:before {
    content: "\f104";
    font-family: fontawesome;
    display: none;
  }
}

