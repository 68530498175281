@import "resources/styles/scss/mixins.scss";

.home {
  margin-top: 0;

  .feedback {
    margin-top: 5em;
    
    @include max(93em){
      margin-top: 0;
    }
  }
}
