//  Forms custom styles

.mbd-input-place-holder {
    color: #949494;
}

.mbd-input-text-generic {
    height: calc($base-root-size * 5);
    padding: 0 27px;
    font-size: 16px !important;
    background: $gray3 !important;
    border: 1px solid $gray3;
    transition: $transition-default;
    color: $default;
    font-family: $font-roboto;
    font-weight: 400;
}

//  how to change placeholder color?

.input-group {
    margin-bottom: 30px;
    position: relative;

    .error-message {
        color: $orange3;
        font-size: 12px;
        font-weight: 500;
        line-height: 132.19%;
        margin-top: $spacer1;
    }
}

// Forms
label {
    margin-right: $spacer2;
    margin-bottom: $spacer1;
    font-size: 1rem;
    line-height: 110.69%;
    font-family: $font-roboto;
    font-weight: 500;
}
 
textarea,input[type]  {
    @extend .mbd-input-text-generic;
    outline: none;
    &:focus,
    &:focus-visible,
    &:focus-within {
        outline: 1px solid $gray4;
        border: 1px solid $gray4;
        box-shadow: none;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        @extend .mbd-input-place-holder;
        opacity: 1; /* Firefox */
      }
      
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        @extend .mbd-input-place-holder;
      }
      
    &::-ms-input-placeholder { /* Microsoft Edge */
        @extend .mbd-input-place-holder;
      }

    &.input-error {
        border: 1px solid $orange3;
        outline: 1px solid $orange3;

        &::after {
            content: '';
            border: 10px solid red;
            display: block;
        }
    }
}

textarea {
    min-height: 80px;
    font-size: local 16px !important;
    padding-top: $spacer1;
}
