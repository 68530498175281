@import "resources/styles/scss/variables.scss";
@import "resources/styles/scss/mixins.scss";

.feedback {
    padding: 5em 0;
    @include max($maxSM) {
        padding: 2em 0;
    }

    @include max($maxMD) {
        padding-top: 2em;
        padding-bottom: 3.5em;
    }
    .component {
        display: flex;
        justify-content:space-between;
        padding: 0 10px;

        @include max($maxLG) {
            flex-direction: column;
            align-items: center;
        }
        
    .disclaimer {
            padding: 20px;
            width: 100%;
            max-width: 26.25em;
            @include max($maxLG) {
                margin-bottom: 2em;
            }
            h2 {
                font-weight: 700;
                margin-bottom: 1.875rem;

                @include max($maxLG) {
                    text-align: center;
                }

                .type2 {
                    color: $red2;
                }
            }

            h4 {
                font-size: 1.5em;
                font-weight: 400;
                margin-bottom: 0.5em;
                line-height: 1.2em;

                @include max($maxLG) {
                    text-align: center;
                }
            }

            p {
                font-size: 1.28em;
                @include max($maxLG) {
                    text-align: center;
                    
                }
            }
        }
    }
}