@import "./typography.scss";
@import './bs5/functions.scss';

$font-roboto: 'Roboto', sans-serif;
$font-open-sans: 'Open Sans', sans-serif;

//MBD Colors
$dark: #262626;
$default: #000000;
$gray: #404040;
$gray1: #4D4D4D;
$gray2: #707070;
$gray3: #F5F5F5;
$gray4: #8E8E8E;
$gray5: #6C757D;
$white1: #EDEDED;
$white2: #ffffff;
$white3: #E5E5E5;
$orange: #FF7900;
$orange2: #cd6905;
$orange3: #E67C00;
$red1: #CC0000;
$red2: #FF0000;
$red3: #cf0000;
$yellowL: #FEFF9C;
$yellow2: #ffd400;


// breakpoints
$minXS: 0;
$maxXS: 575px;
$minSM: 576px;
$maxSM: 767px;
$minMD: 768px;
$maxMD: 991px;
$minLG: 992px;
$maxLG: 1199px;
$minXL: 1200px;
$maxXL: 1399px;
$minXXL: 1400px;

// TYPOGRAPHY
$base-font-size: 16px;
$base-root-size: 8px;

// TRANSITION
$transition-default: 300ms ease all;

// SPACER
$spacer1: calc($base-root-size * 1);
$spacer2: calc($base-root-size * 2);
$spacer3: calc($base-root-size * 3);
$spacer4: calc($base-root-size * 4);
$spacer5: calc($base-root-size * 5);
$spacer6: calc($base-root-size * 6);
$spacer7: calc($base-root-size * 7);
$spacer8: calc($base-root-size * 8);
$spacer9: calc($base-root-size * 9);

$input-border-radius:           6px;
$input-border-radius-lg:        6px;
$input-border-radius-sm:        6px;

$input-btn-font-size: 18px;

$btn-padding-x: 2.5rem;
$btn-font-size: 18px;
$btn-border-radius: 6px;