@import './reset.scss';
@import './bs5/variables.scss';
@import './variables.scss';
@import './typography.scss';
@import './mixins.scss';

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-size: 70% !important;

  background: #ffffff !important;
  font-family: $font-roboto;
  position: relative;
  line-height: normal;

  p {
    font-size: 1.4em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-open-sans;
  }

  h1 {
    font-size: 5em;
  }

  h2 {
    font-size: 3.375em;
  }

  h3 {
    font-size: 2.25em;
  }

  h4 {
    font-size: 1.875em
  }
}

//v2 - headers
.mbd-title {
  h1 {
    font-size: 40px;

    @include max($maxSM) {
      font-size: 32px;
    }
  }

  h2 {
    font-size: 18px;

    @include min($minMD) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 16px;

    @include max($maxSM) {
      font-size: 14px;
    }
  }

  h4 {
    font-size: 10px;

    @include max($maxSM) {
      font-size: 10px;
    }
  }
}

