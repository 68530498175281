@import "../../../resources/styles/scss/mixins.scss";
@import "../../../resources/styles/scss/variables";
#formBanner-1 {
    @include max($maxSM) {
        display: none;
    }
}

#formbanner-title-1 {
    margin-top: 170px;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        letter-spacing: 0;
        font-size: 30px;
        line-height: calc($base-root-size * 4)
    }
}