@import "resources/styles/scss/variables";
@import "resources/styles/scss/mixins";

.UnderDev {
    width:100%;
    min-height: 100vh;

    padding: 20px;
    background: $red2;

    .brandLogo {
        width: 100%;
        display: flex;
        justify-content:center;
        margin-bottom: 5.5em;
        margin-top: 67px;

        @include max($maxMD) {
            margin-bottom: 4.125em;
            margin-top: 3em;
        }


        svg {
            width: 369px;
            @include max($maxMD) {
                max-width: 208px;
            }
        }
    }

    .PageHeadline {
        display: flex;
        justify-content:center;
        margin-bottom: 5.5em;

        @include max($maxMD) {
            margin-bottom: 40px;
        }

        h1 {
            text-align: center;
            max-width: 900px;
            color: #ffffff !important;
            font-family: $font-open-sans;
            font-size: 5.6em;
            font-weight: 700;

            @include max($maxMD) {
                font-size: 3em;
                max-width: 400px;
            }
        }
    }

    .Email{
        margin-top: 1em;
        display: flex;
        justify-content:center;

        h3 {
            display: inline !important;
            width:auto;
            text-align: center;
            color: rgb(29, 28, 28) !important;
            font-size: 1.2rem;
            font-weight: 600;

            @include min($minLG) {
                font-weight: 600;
                font-size: 2em;
            }

            a {
                color: white;
            }
        }
    }

    .Call{
        margin-top: 1em;
        display: flex;
        justify-content:center;

        @include max($maxMD) {
            margin-top:0;
        }

        svg {
            width: 2.75em;
            
            @include max($maxMD) {
                width:1.75em;
            }
            
        }

        h3 {
            display: inline !important;
            width:auto;
            text-align: center;
            font-size: 2em;
            color: white !important;

            a {
                color: white;
                font-weight: 600;
            }
        }
    }


    .Copy {
        display: flex;
        justify-content:center;
        margin-top: 5.75em;

        h3 {
            display: inline !important;
            width:auto;
            text-align: center;
            font-size: 1rem;
            color: white !important;
            font-weight: 200;

            @include min($minLG) {
                font-size: 2em;
            }
        }
    }
}

// .footer, .header {
//     display: none;
// }