@import "resources/styles/scss/mixins.scss";
@import "resources/styles/scss/variables.scss";

.voucher-slide {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;


    .slider-wrap {
        width: calc(100% - 40px);
        
        @include max($maxSM) {
            width: calc(100% - 40px);
        }


    }

    .arrow {
        width: 15px;
        padding: 0 0px;

        &.right {
            transform: translateX(-10px) !important;

            @include max($maxSM) {
                opacity: 0 !important;
                transform: translateX(0) !important;
                pointer-events: none;
            }
            
        }
        
        &.left {
            transform: translateX(10px) !important;

            @include max($maxSM) {
                opacity: 0 !important;
                pointer-events: none;
                transform: translateX(0) !important;

            }
        }

        &:hover {
            cursor: pointer;

            img {
                transform:scale(1);
                position: relative;
            }
        }

        img {
            transition: all 300ms ease;
            transform: scale(1);
            display: block;

            @include max($maxMD) {
                transform: scale(.5);
                // border: 1px solid red;
            }
        }
    }
}