
@import "resources/styles/scss/mixins.scss";
@import "resources/styles/scss/variables.scss";

.MBDClassic {
    padding-bottom: 7em;

    @include max($maxMD) {
        padding-bottom: 6em;
    }


    .component {
        

        // @include max($maxSM) {
        //     padding-left: 5.5em;
        //     padding-right: 5.5em;
        // }
    }

    .inner-wrap {
        padding-top: 10.5em;
        padding-left: 8.75em;
        padding-right: 8.75em;
        padding-bottom: 6em;

        background: url(../../../resources/images/mbd-classic/mbd-classic.jpg) center center;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: space-between;

        @include max($maxMD) {
            padding-top: 12em;
            padding-left: 3em;
            padding-right: 3em;
            padding-bottom: 4em;
        }

        display: flex;
        align-items: flex-end;
    }

    .textWrap {
        h2 {
            color: white;
            font-weight: 700;
            font-size: 5.2em;
            margin-bottom: .1em;

            @include max($maxSM) {
                font-size: 3em;
                margin-bottom: .3em;
            }

            span {
                font-weight: 100;
                font-family: $font-roboto;
            }
        }

        h3 {
            color: white;
            font-weight: 300;
            font-size: 1.8em;
            max-width: 600px;
            line-height: 1.5em;
            font-family: $font-roboto;
            margin-bottom: 0;

            @include max($maxSM) {
                font-size: 1.5em;
                max-width: 250px;
            }

        }
    }

    .playButton {
        width: 6.5em;
        cursor: pointer;
        @include max($maxSM) {
            width: 4.5em;
        }

        svg {
            transition: all 300ms ease;
            circle {
                transition: all 300ms ease;
                fill: #fff !important;
            }

            polygon{
                transition: all 300ms ease;
            }
        }

        &:hover {
            svg {
                circle {
                    fill: $red2 !important;
                }
                polygon {
                    fill: #fff !important;
                }
            }
        }
    }
}

.mbd-modal {
    
    .modal-dialog {
        border: 0;
        width: 80em !important;
        max-width: 80em !important; 
        margin: 0 auto;

        @include max($maxMD) {
           width: 600px !important
        }

        @include max($maxSM) {
            width: 500px !important; 
        }

        @include max(540px) {
            width: 350px !important;
        }

        .modal-content {
            border: 0;
            background: transparent;
        }
        .modal-header {
            border: 0;
            background: transparent;
            padding: 0;

            .close-btn {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                min-height: 30px;

                svg {
                    position: absolute;
                    
                    &:hover {
                        cursor: pointer;
                    }

                    @include min($minLG) {
                        transform: scale(1.5) translate(-3px, -5px) !important;
                    }
                }
            }
        }
        .modal-body {
            background: #000;
            padding: 0;
            // REACT-PLAYER

            
            .react-player {
                width: 100% !important;
                height: 45em !important;
                display: block;

                @include max($maxMD) {
                    min-height: unset;
                    height: 30em !important;
                }

                @include max($maxSM) {
                    height: 25.1em !important;
                }

                @include max(540px) {
                    height: 17.6em !important;
                }

                iframe {
                    width: 100%;
                }
            }

            .youtube-contact-invite {
                display: flex;
                padding: 2.2em;

                @include max(540px) {
                    flex-direction: column;
                }

                .content-wrap {
                    flex-grow: 1;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 0.5em;

                    h2 {
                        color: white !important;
                        font-weight: 700;
                        font-size: 2.8em;
                        padding-bottom: 0.1em;

                        @include max($maxMD) {
                            font-size: 2em;
                            padding-bottom: .5em;
                        }

                        @include max($maxSM) {
                            font-size: .2em;
                            padding-bottom: .5em;
                        }

                        @include max(540px) {
                            font-size: 1.9em;
                            text-align: center;
                        }
        
                    }

                    h3 {
                        font-size: 1.8em;
                        color: white;
                        font-weight: 400;

                        @include max($maxMD) {
                            font-size: 1.5em;
                        }

                        @include max($maxSM) {
                            font-size: 1.2em;
                        }

                        @include max(540px) {
                            font-size: 1.3em;
                            text-align: center;
                            line-height: 90%;
                        }
        
                    }
                }

                .btn-wrap {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    @include max(540px) {
                        justify-content: center;
                        margin-top: 1em;
                    }


                    .btn {
                        background: $red2;
                        padding: .7em 5em;
                        font-size: 1em;
                        letter-spacing: 0.3px;
                        border-radius: 7px;
                        font-weight: 700;
                        font-family: $font-open-sans !important;

                        @include max($maxMD) {
                            padding: .8em 1.5em;
                            font-size: .5em;
                        }

                        @include max($maxSM) {
                            padding: .9em 2em;
                            font-size: 1.2em;
                            font-weight: 600;
                        }

                        @include max(540px) {
                            padding: .3em 1.8em;
                            font-size: .5em;
                        }

                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }
        }

        .modal-footer {
            padding: 0;
            background: #4D4D4D;
            display: flex;
            border-top: 0 !important;

            .subscription {
                display: flex;
                justify-content: center;
                padding: 1em;
                margin: 0;
                width: 100%;
                align-items: center;

                a {
                   color: white !important; 
                   display: flex;
                   align-items: center;
                   font-size: 0.9em;
                   
                  

                }

                span {
                    letter-spacing: 1px;
                    color: white !important;
                    font-size: 1.5em;
                    font-family: $font-open-sans;
                    font-weight: 700;
                    margin-right: 10px;

                    @include max($maxMD) {
                        font-size: .8em;
                    }
                    @include max(540px) {
                        font-size: .9em;
                        font-weight: 600;
                    }
                }

                svg {
                    @include max($maxMD) {
                        width: 30px;
                    }

                    @include max(540px) {
                        width: 20px;
                    }
                }
            }
        }
    }
}