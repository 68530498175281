@import "resources/styles/scss/variables.scss";
@import "resources/styles/scss/mixins.scss";

.MenuRibbon {
    position: relative;
    z-index: 11;

    .menu-ribbon-slide {
        background: #000 !important;
    
        .ribbon-text {
            padding: 10px 0;
            width: 100%;
    
            p {
                color: $white2;
                font-size: 1.5em;
                text-align: center;
                margin: 0;
                width: 100%;

                @include max($maxSM) {
                    font-size: 1.1em;
                    font-weight: 450;
                }
            }
        }
    }

    .triangle-wrap {
        display: flex;
        justify-content:center;
        
        .triangle {
            width: 30px;
            border-top: 10px solid black;
            border-bottom: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        }
    }
}