@import "../mixins.scss";
@import "../variables.scss";

.hide {
    display: none;

    &__to-sm{
        @include max($maxSM) {
            display:none;
        }
    }

    &__on-sm-only {
        @include min-max($minSM, $maxSM) {
            display: none;
        }
    }


    // MD 
    &__to-md {
        @include max($maxMD) {
            display: none;
        }
    }

    &__on-md-only {
        @include min-max($minMD, $maxMD ) {
            display: none;
        }
    }

    &__from-md {
        @include min($minMD) {
            display: none;
        }
    }



    // LG
    &__to-lg {
        @include max($maxMD) {
            display: none;
        }
    }

    &__on-lg-only {
        @include min-max($minLG, $maxLG) {
            display: none;
        }
    }

    &__from-lg {
        @include min($minLG) {
            display: none;
        }
    }


    // XL
    &__on-xl {
        @include max($maxLG) {
            display: none;
        }
    }

    &__on-xl-only {
        @include min-max($minLG, $maxLG) {
            display: none;
        }
    }
    
    &__from-xl {
        @include min($minXL) {
            display: none;
        }
    }
}


.show {
    display: inline;

    &__to-sm{
        @include max($maxSM) {
            display:inline;
        }
    }

    &__on-sm-only {
        @include min-max(576px, $maxSM) {
            display: inline;
        }
    }


    // MD 
    &__to-md {
        @include max($maxMD) {
            display: inline;
        }
    }

    &__on-md-only {
        @include min-max($minMD, $maxMD ) {
            display: inline;
        }
    }

    &__from-md {
        @include min($minMD) {
            display: inline;
        }
    }



    // LG
    &__to-lg {
        @include max($minLG) {
            display: inline;
        }
    }

    &__on-lg-only {
        @include min-max($minLG, $maxLG) {
            display: inline;
        }
    }

    &__from-lg {
        @include min($minLG) {
            display: inline;
        }
    }


    // XL
    &__on-xl {
        @include max($maxLG) {
            display: none;
        }
    }

    &__on-xl-only {
        @include min-max($minLG, $maxLG) {
            display: none;
        }
    }
    
    &__from-xl {
        @include min($minXL) {
            display: none;
        }
    }
}

.shift {
    position: absolute;
    transform: translateY(100px);
    transition: all 300ms ease;
    
}

.unshift {
    position: absolute;
    transform: translateY(0px);
    transition: all 300ms ease;
}


// font-weights
.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}