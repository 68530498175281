@import "resources/styles/scss/variables.scss";
@import "resources/styles/scss/mixins.scss";

.scroll-up-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    @include min($minLG) {
        display: none;
    }
}
.ScrollUp {
    width: 4rem;
    height: 4rem;
    z-index: 1;
    right: 0;
    bottom: 5rem;
    padding: 2rem;
    margin-right: 1rem;
    border: 1px solid white;
    display: flex;
    align-items: center;
    border-radius: 100%;
    transition: all 600ms ease;
    opacity: 0;
    transform: translateY(30px);

    &:hover {
        background-color: rgba(#fff, .6);
        cursor: pointer;
    }


    @include max($maxMD) {
       padding: 0;
       width: 3rem;
       height: 3rem;
    }
    
    

    .ArrowUp_solid-white {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        svg {
            width: 1.5rem;
            margin-top: -.3rem;

            @include max($maxMD) {
                width: 1rem;
            }
        }
    }
}

.showScrollUp {
    @include max($maxMD) {
        opacity: 1;
        transform: translateY(0);
    }
}

