@import "../../../../resources/styles/scss/variables.scss";
@import "../../../../resources/styles/scss/mixins.scss";

.bannered {
    position: relative;
    min-height: 100vh;
    background: $white3;
    padding-bottom: calc($spacer1 * 31);
    
    @include max($maxSM) {
        padding-bottom: calc($spacer1 * 7);
    }
    
}