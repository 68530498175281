@import "resources/styles/scss/mixins.scss";
@import "resources/styles/scss/variables.scss";

.testimonial_slider{
    // background: #333;
    width: 100%;
    max-width: 55em;
    display:flex;
    flex-grow: 1;
    position: relative;
    transform: translate(-60px);


    @include max($maxLG) {
        max-width:100%;
        transform: translate(0) !important;
    }

    .arrow-wrap {
        min-width: 25px !important;
        display:flex;
        align-items: center;
        padding: 5px;
        position: relative;
        z-index:100;

        &:hover {
            cursor: pointer;
        }

        path {
            opacity: .2;
            fill: #000;
        }

        .ArrowLeft__solid-white, .ArrowRight__solid-white {
          width:60%;  
        } 

    }
    .arrow {
        width: 10px !important;
        opacity: 1;
        display: inline;
        font-size: 16px !important;
        
        svg {
            font-size: 30px;
        }
    }
    .innerSlider {
        width: 100%;

        @include max($maxLG) {
            width: calc( 100% - 50px);
        }
    }
    .swiper-wrapper {
        display:flex;
    }
    .slide {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 29em;
        @include max($maxMD) {
            height: 31em;
        }

        .inner-wrap {
            width: 100%;
            max-width: 80%;
            min-height: 100%;
            display: flex;
            flex-direction: column;
        }

        .img-wrap {
            width: 100%;
            max-width:135px;
            display: block;
            margin: 0 auto;
            margin-bottom: 1.0625em;
            overflow: hidden;
            border-radius: 100%;
            
            img {
                width: auto;
                border-radius: 100px;
                transform: scale(1.40);
            }
        }

        .business-details {
            margin-bottom: 1.7em;

            p {
                text-align: center;
                font-size: 1.5em;
                margin: 0;
            }

            .profile {
                font-size: 1.3em;
                font-weight: bold;
                margin-bottom: .2em;
            }

            .position {
                font-weight: 400;
                font-size: 1.1em;
            }

            .business {
                font-weight: 400;
                white-space: nowrap;
                font-size: 1.1em;
                color:$orange2;
            }
        }

        .message-details {
            flex-grow: 1;
            display: flex;
        
            
            @include max($maxMD) {
                flex-grow: 0.2;
                height: 6em;
            }
            

            p {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                white-space: wrap;
                width:100%;
                font-size: .8rem;
                font-family: $font-roboto;
                text-align: center;
                display: flex;
                flex-direction: column;
                position: relative;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical; 

                @include max($maxMD) {
                    text-align: center;
                }
            }

            .readMore {
                position: absolute;
                bottom: 0;
                width: 100%;
                margin-top: 10px;
                display:block;
            }
        }

        .stars {
            display: flex;
            justify-content: center;

            svg {
                margin: 0 4px;
                font-size: 30px;
                color: #f1d856;
            }
        }
    }
}