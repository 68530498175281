@import './variables.scss';

* {
  box-sizing: border-box;
}

.component {
  display: block;
  max-width: calc(960px + calc(32px * 2));
  width: 100%;
  float: none;
  margin: 0 auto;
  padding: 0 3em;

  @include max($maxMD) {
    padding: 0 3em;
  }
}

img {
  display: block;
}

.mbd-btn {
  border-radius: 0.4rem !important;
  font-family: $font-roboto !important;
  font-weight: 400 !important;
  font-size: 2em !important;
  padding: 1rem !important;
  min-width: 14rem !important;
  box-sizing: border-box !important;
  line-height: 100% !important;
}

.mbd-btn-white-bg {
 @extend .mbd-btn;
 background-color: $white2 !important;
 color: $red !important;

 &:hover {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: $white2 !important;
 }
}
