@import "../scss/bs5/mixins/buttons";

@mixin max($max) {
    @media (max-width: $max) { @content; }
}

@mixin min($min) {
    @media (min-width: $min) { @content; }
}


@mixin min-max($min, $max) {
    @media (min-width: $min) and (max-width:$max) {@content}
}


@function column($column) {
    $result: calc(100vw / 12) * $column;
    @return $result ;
}

