.discordbtn {
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: .8rem !important;
    padding-bottom: .8rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    border-radius: 0 !important;
    font-size: 1.19rem !important;
    white-space: nowrap;
    background-color: transparent !important;
    font-weight: 800;
    color: white;
    text-decoration: none !important;

    img {
        margin-right: .7rem;
        min-width: 22px;
    }
}