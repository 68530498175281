@import "../../../resources/styles/scss/variables.scss";
@import "../../../resources/styles/scss/mixins.scss";

.martleyNutshell {
    background: #000000;

    .component {
        padding: 3em 2.5em;
          background: url(../../../resources/images/about/mbd-about.jpg) center center no-repeat;
        background-size: cover;

        .title {
            padding-top: 18em;
            padding-bottom: 3em;

            @include max($maxMD) {
                padding-top: 15em;
                padding-bottom: 0em;
            }
            
            h2 {
                font-size: 3.875em;
                font-weight: bold;
                color: $white2;
                margin-bottom: .3em;
                text-shadow: 0 0 10px rgba(#000, 1);
                span {
                    color: $red2;
                }
            }

            h4 {
                color: $white2;
                font-size: 1.8em;
                font-weight: 400;
                font-family: $font-roboto;
            }
        }
    }
}