@import "../../../resources/styles/scss/variables.scss";
@import "../../../resources/styles/scss/mixins.scss";

.newsOffers {
    background: #f1d856;

    .component {
        padding-top: 3em;
        padding-bottom: 3em;
        
        @include max($maxSM) {
            padding-left: 5.5em;
            padding-right: 5.5em;
        }

        .content-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include max(500px) {
                flex-direction: column;
                align-items: center;
                justify-content:center;
            }
        }
    }

    .title-wrap {
        &.show-below-767 {
            display: none;
            width: 100%;
            h2 {
                width: 100%;
                max-width: 400px;
                text-align: center;
                margin: 0 auto;
                position: relative;
            }

            @include max($maxSM) {
               display: flex;
               justify-content: center;
               margin-bottom: 0em !important;
               position: relative;
            }
        }

        &.show-above-767 {
            display: none;
            @include min($minMD) {
                display: block !important;
            }
        }
        
        h2 {
            font-size: 3em;
            font-weight: bold;
            span {
                color: $red2;
                display: block;
            }
        }
    }

    p {
        font-size: 1.65em;
        font-family: $font-roboto;
        font-weight: 400;
        padding-top: .4em;

        @include max($maxSM) {
            text-align: center;
            padding-top: 1em;
        }
    }

    .btn-wrap {
        padding-left: 5em;

        @include max(500px) {
            padding-left: 0; 
            padding-top: 2em;  
        }
    }

    Button {
        background:#333333;
        border: 1px solid #333333;
        font-size: 2em;
        font-weight: 400;
        white-space: nowrap;
        padding: .7em 2em;
        outline: none !important;

        @include max(500px) {
            padding: .7em 5.2em;
        }
        &:hover, &:active, &:focus {
            outline: none !important;
            background: #111 !important;
            border: 1px solid #111;
        }
        
    }
}