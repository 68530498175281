@import "../../../resources/styles/scss/variables.scss";
@import "../../../resources/styles/scss/mixins.scss";


.menu-bar {
  z-index: 10;
  font-family: $font-roboto;
  font-weight: 300;
  color: $white2;
  background: $red3;
 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 100%;

  @include max($maxMD) {
    position: fixed;
    // padding-top: 0;
    // padding-bottom: 1em;
  }

  .component {
    transition: all 300ms ease;
 
    &.tall {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.short {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    @include max($maxSM) {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }

  .inner-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 100%;
      max-width: 15.313em;

  

    }

    .menu {
      display: flex;
      align-items: center;

      > .trigger a {
        color: $white2;
        font-size: 2.125em;
        text-decoration: none;
      }
    }
  }


  &.static {
    .ribbon.tall{
      display: none;
    }
  }
}



.burger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 3.125em;



  &:hover {
    cursor: pointer;
  }

  .dot {
    background-color: $white2;
    width: 0.625em;
    height: 0.625em;
    border-radius: 0.625em;
    margin-bottom: 0.375em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.kebab-wrap{
  scroll-behavior: smooth;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.ribbon {
  transition: all 600ms ease;
  position: relative;
  overflow: hidden;
  
  &.tall {
    height: 55px;
  }

  &.short {
    height: 0;
    min-height: 0;
  }
}

.logo-symbol {
  width: 4rem;
  padding-top: 5px;
  padding-bottom: 5px;
}