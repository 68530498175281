@import "../../../resources/styles/scss/variables.scss";
@import "../../../resources//styles/scss/mixins.scss";
.bizNeeds {
    background: #fff2de;
    .component {

        padding-top: 3em;
        padding-bottom: 3em;

        // @include max(767px) {
        //     padding: 5.5em;
        // }

        .inner-wrap {
            display: flex;
            justify-content: space-between;
            
            @include max($maxMD) {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &__img-wrap {
        width: 48.75em;
        margin-right: column(1);

        @include max($maxMD) {
            width: 100%;
            max-width: 700px;
            margin-right: 0;
        }
    }

    .title {
        margin-bottom: 1.5em;
        width: 100%;
    }


    &__contents {
        width: 30.75em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include max($maxMD) {
            margin-top: column(1);
            width: 100%;
        }


        h2 {
            font-size: 3.5em;
            font-weight: 700;
            white-space: nowrap;

            @include max($maxMD) {
                text-align: center;
                font-size: 3.375em;
            }

            span {
                color: $red2;
            }
        }

        h3 {
            font-size: 1.65em;
            line-height: 150% ;
            font-weight: 400;

            @include max($maxMD) {
                text-align: center;
                font-size: 1.625em;
            }
        }

        .btn-wrap {
            margin-top: 3em;
            width: 100%;

            @include max($maxMD) {
                width: 30em;
            }

            .btn {
                min-width: 100% !important;
                font-size: 5em;
                letter-spacing:1px;
                font-weight: 400;
                background: $red2;
                padding: .7em !important;

                &:hover {
                    background: $red1 !important;
                }
            }
        }
    }
}