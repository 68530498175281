@import "resources/styles/scss/mixins.scss";
@import "resources/styles/scss/variables.scss";


.subscription {
    position: relative;
    overflow: hidden;

    .component {
        padding: 0 2.5em;

        @include max($maxSM) {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }

    .inner-wrap {
        padding: 1em;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: ' ';
            width: column(12) !important;
            height: column(1);
            max-height: 100px;
            background: $red3;
            position: absolute;
            top: 0;
            left: 0;    
            z-index: 0;

            @include max($maxMD) {
                height: column(1);
            }

        }

        .img-responsive {
            position: relative;
            z-index: 1;
            width: 100%;
            margin: 0;
            display: inline-block;
            
            @include max($maxMD) {
                padding: 1em;
                width: calc(100% - 20px);
            }

            @include max($maxSM) {
                padding: 0;
                width: calc(100% - 6px);
            }
        }
    }
}