@import "resources/styles/scss/variables";
@import "resources/styles/scss/mixins";

.core-services {
    .component {
        // @include max(767px) {
        //     padding-left: 5.5em;
        //     padding-right: 5.5em;
        // }
    }
    .title {
        margin-top: 4.375em;
    }
}