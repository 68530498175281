@import "../../../resources/styles/scss/mixins.scss";
@import "../../../resources/styles/scss/variables";

#contact-us-success-message {
    padding-top: 36px;
    padding-bottom: 20px;
    padding-left: 22px;
    padding-right: 22px;

    
    @include min($minMD) {
        min-height: 600px;
    }

    .mbd-title {
        h1 {
            margin-bottom: 17px;
        }
        h2 {
            font-family: $font-roboto;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0;
        }

        h3 {
            font-family: $font-roboto;
            margin-top: 21px;
            letter-spacing: 0;
            margin-bottom: 28px;
        }
    }
}