@import "../../../resources/styles/scss/variables.scss";
@import "../../../resources/styles/scss/mixins.scss";

.preloader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  svg {
    -webkit-animation: spin 1100ms linear infinite;
    -moz-animation: spin 1100ms linear infinite;
    animation: spin 1100ms linear infinite;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
