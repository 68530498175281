@import "resources/styles/scss/variables";
@import "resources/styles/scss/mixins";

.services {
    overflow-y: hidden;

    .service {
        padding-top: 13px;
        padding-bottom: 5em;
        display:flex;
        box-sizing: border-box !important;

        @include max(600px) {
            padding-top: 0;
            padding-bottom: 1em;
            min-height: 250px;
        }

        @include max(450px) {
            padding-top: 0;
            padding-bottom: 1em;
            min-height: 360px;
        }

        // border :1px solid red;

        .icon {
            img {
                // width: 6.875em;
                width: 110px;
            }
        }
        .details {
            display: flex;
            justify-content:space-between;
            width: 100%;

            &__texts{
                padding-left: 2.5em;
                display: flex;
                justify-content: flex-start;
                flex-direction:column;
                

               
                width: 100%;

                &__title{
                    margin-bottom: 1em;
                    h4 {
                        letter-spacing: -1px;
                        font-size: 1.8875em;
                        font-weight: bold;
                        margin: 0;

                        @include max($maxSM) {
                            font-size: 1.8em;
                        }
                    }
                }
                &__content{
                    display: flex;
                    justify-content: space-between;
                    flex-grow: 1;
                    // border: 1px solid green;

                    @include max($maxMD) {
                        flex-direction:column;
                    }

                    
                    &__inner-wrap {
                        max-width: 35.125em;
                        line-height: 140%;
                        width: 100%;
                        font-size: 1.4em;
                        font-weight: 400;
                        letter-spacing:0px;
                        font-family: $font-open-sans;
                        flex-grow: 0;
                        
                        @include max($maxMD) {                        
                            font-size: 1.4em;
                            max-width: 100%;
                        }
                        @include max($maxSM) {
                            line-height: 160%;
                        }
                    }

                    .btn-wrap{
                        display: flex;
                        flex-grow: 1;
                        justify-content: flex-end;
                        align-items: center;
                        font-size: 16px;

                        @include max($maxMD) {
                            margin-top: .0em;                            
                        }
                        @include max($maxSM) {
                            margin-top: .0em;                            
                        }       
                        .btn__service {
                            // min-width: 13.75em;
                            line-height: 1em;
                            padding: 1rem 3.455rem;
                            text-align: center;
                            font-size: 1.25em;
                            font-weight: 600;
                            letter-spacing: 1px;
                            border: 0;
                            // added rounded corner 12.28.2022 - Ley
                            border-radius: .3em;
                            box-sizing: border-box;
                            white-space: nowrap;
                            background: $red2;
                            color: #ffffff !important;
                            transition: all 300ms ease;
                            font-family: $font-open-sans;

                            @include max($maxMD) {
                                font-size: 1.2em;
                                padding: 1rem 3.5rem !important;
                            }
                            @include max($maxSM) {                                
                                font-size: 1.2em;
                                padding: 1rem 3.5rem !important;
                            }
                            &:hover {
                                background: $red1;
                            }
                        }
                    }
                }
            }
            
        }
    }
}
.services-text-toggle {
    width:100%;
    text-align: center;
    font-size: 1.5em;
    font-weight: 600;
    font-family: $font-open-sans;
    margin-top: 1.5em;
    &:hover {
        cursor: pointer;
    }
    &:after {
        width: 34px;
        height: 6px;
        content: '';
        background: $red2;
        display: block;
        margin: 10px auto;
    }
}