@import "resources/styles/scss/variables";
@import "resources/styles/scss/mixins";

.SocMed {
    width:100%;
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items:center;

    .text{
        margin-bottom: 1em;
        h3 {
            text-align: center;
            color: white !important;
            font-weight: 400;
            font-size: 1rem;

            @include min($minLG) {
                font-weight: 600;
                font-size: 2em;
            }
        }
    }

    .SocMed__Icons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content:center;
        flex-wrap: wrap;

        .icon {
            margin: .5em .75em;

            svg {
                height: auto;
                width: 5.5em;

                @include max($maxMD) {
                    width: 4em;
                }
            }
        }
    }
}